import { IRecommendations } from '../../../types/newbuildingRecommendations';
import { TBanners } from '../../../types/newbuildingBanners/banners';
import { TCompilationType } from '../../../types/newbuildingBanners/compilationBanner';
import { TPromoBannerType } from '../../../types/newbuildingBanners/promoBanner';
import { TTopPromoBannerType } from '../../../types/newbuildingBanners/topPromoBanner';

export type TBannerTypes = TCompilationType | TPromoBannerType | TTopPromoBannerType;

const banners = ['lastChanceDiscount', 'topPromos', 'allPromos', 'kpn', 'promosFlight', 'topPromo'];

export const isNewbuildingBanner = (item: IRecommendations | TBanners): item is TBanners => {
  return !!(item.type && banners.includes(item.type));
};
