import { actionGenerator } from '../../utils/redux/actionGenerator';

import { TThunkAction } from '../../types/redux';
import { EGetNewbuildingBannersStatusActionType, TGetNewbuildingBannersSucceed } from './types';
import { IPromoBanners } from '../../types/newbuildingBanners/newbuildingBanners';
import {
  fetchGetPromoBanners,
  TGetPromoBannersResponse,
} from '../../repositories/newbuilding-promos/v1/get-promo-banners';
import { mapNewbuildingBannersResponse } from './mappers/mapNewbuildingBannersResponse';
import { assertStatusCode200 } from './assert/assertStatusCode';

export const setNewbuildingBannersLoading = actionGenerator<EGetNewbuildingBannersStatusActionType.Loading>(
  EGetNewbuildingBannersStatusActionType.Loading,
);
export const setNewbuildingBannersFailed = actionGenerator<EGetNewbuildingBannersStatusActionType.Failed>(
  EGetNewbuildingBannersStatusActionType.Failed,
);

export const setNewbuildingBannersSucceed: (recommendations: IPromoBanners) => TGetNewbuildingBannersSucceed =
  actionGenerator<EGetNewbuildingBannersStatusActionType.Succeed, IPromoBanners>(
    EGetNewbuildingBannersStatusActionType.Succeed,
  );

export function getNewbuildingBanners(): TThunkAction {
  return async (dispatch, getState, context) => {
    const {
      httpApi,
      custom: { subdomain },
    } = context;
    dispatch(setNewbuildingBannersLoading());
    try {
      const response: TGetPromoBannersResponse = await fetchGetPromoBanners({
        httpApi,
        parameters: { subdomain },
      });

      assertStatusCode200(response);

      dispatch(setNewbuildingBannersSucceed(mapNewbuildingBannersResponse(response.response)));
    } catch (error) {
      dispatch(setNewbuildingBannersFailed());
    }
  };
}
