import { ca } from '@cian/analytics';
import { TBannerTypes } from '../helpers/isNewbuildingBanner';

const typeToAnalyticsTypes: Record<TBannerTypes, string> = {
  kpn: 'kpn',
  promosFlight: 'promo_flight',
  topPromo: 'promo_special_offers',
  topPromos: 'promo_best_offers',
  lastChanceDiscount: 'promo_last_chance_discount',
  allPromos: 'promo_discounts_and_offers',
};

export const onRecommendationsSectionShowAnalytic = (products: { id: number; position: number }[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_carousel',
    action: 'show_sopr',
    label: 'nb_recomend_jk',
    page: {
      pageType: 'Home',
      siteType: 'desktop',
      extra: {
        products,
      },
    },
  });
};

export const onShowAllClickAnalytic = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_carousel',
    action: 'click_sopr',
    label: 'nb_recomend_all',
    page: {
      pageType: 'Home',
      siteType: 'desktop',
    },
  });
};

export const onCardClickAnalytic = ({ newbuildingId, position }: { newbuildingId: number; position: number }) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_carousel',
    action: 'click_sopr',
    label: 'nb_recomend_jk',
    page: {
      pageType: 'Home',
      siteType: 'desktop',
      extra: { to_object: newbuildingId, object_type: 'jk', position },
    },
  });
};

export const onBannerClickAnalytic = ({ type }: { type: TBannerTypes }) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_carousel',
    action: 'click_sopr',
    label: 'nb_service',
    page: {
      pageType: 'Home',
      siteType: 'desktop',
      extra: { type: typeToAnalyticsTypes[type] },
    },
  });
};

export const onBannerShowAnalytic = ({ type }: { type: TBannerTypes }) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_carousel',
    action: 'show_sopr',
    label: 'nb_service',
    page: {
      pageType: 'Home',
      siteType: 'desktop',
      extra: { type: typeToAnalyticsTypes[type] },
    },
  });
};
