import { HttpBadStatusCodeError } from '@cian/peperrors/shared';
import {
  IGetUserRecommendationsResponse200,
  TGetUserRecommendationsResponse,
} from '../../../repositories/newbuilding-search/v1/get-user-recommendations';

export function assertStatusCode200(
  response: TGetUserRecommendationsResponse,
): asserts response is IGetUserRecommendationsResponse200 {
  if (response.statusCode !== 200) {
    throw new HttpBadStatusCodeError(
      'Не удалось загрузить рекомендации новостроек',
      response.statusCode,
      'shared.services.getNewbuildingRecommendations',
    );
  }
}
