import { INewbuildingsRecommendations, IRecommendations } from '../../../types/newbuildingRecommendations';
import { IPromoBanner } from '../../../types/newbuildingBanners/promoBanner';
import { ICompilation } from '../../../types/newbuildingBanners/compilationBanner';
import { ITopPromoBanner } from '../../../types/newbuildingBanners/topPromoBanner';
import { getRandomBanner } from './getRandomBanner';
import { TBanners } from '../../../types/newbuildingBanners/banners';

interface IMergeBannersAndRecommendations {
  newbuildings: INewbuildingsRecommendations;
  banners: TBanners[];
}
type TItem = IRecommendations | IPromoBanner | ICompilation | ITopPromoBanner;

const BANNER_POSITIONS = [1, 6, 11, 15];

export const mergeBannersAndRecommendations = ({ newbuildings, banners }: IMergeBannersAndRecommendations): TItem[] => {
  const mergedData = [];
  const bannerPositions = BANNER_POSITIONS;
  const randomBannersSet = getRandomBanner({ banners });
  const { recommendations } = newbuildings;

  const getRandomBannerFromSet = () => {
    const type = randomBannersSet.shift();

    return banners.find(item => item.type === type);
  };

  for (let i = 0; i < recommendations.length; i++) {
    const recommendation = recommendations[i];

    if (!bannerPositions.includes(mergedData.length)) {
      mergedData.push(recommendation);
    } else {
      const bannerData = getRandomBannerFromSet();
      if (bannerData) {
        mergedData.push(bannerData);
      }

      mergedData.push(recommendation);
    }
  }

  return mergedData.slice(0, 20);
};
